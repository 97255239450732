<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-form ref="dataForm" @submit.prevent="save">
                    <v-card class="mb-8">
                        <v-card-text>
                            <v-row class="ma-0">
                                <v-col cols="12" class="mb-8">
                                    <ImageUploader 
                                        singleImage
                                        fileInputKey="upload_image"
                                        :imgList.sync="formData.image"
                                        acceptFormat="image"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></ImageUploader>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormInput
                                        label="標題"
                                        :fieldValue.sync="formData.title"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        required
                                    ></FormInput>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormDatePicker
                                        label="發佈日期"
                                        :fieldValue.sync="formData.publish_date"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        required
                                    ></FormDatePicker>
                                </v-col>
                                <v-col cols="12">
                                    <FormTextarea
                                        label="內容"
                                        :fieldValue.sync="formData.content"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></FormTextarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-btn depressed color="primary accent-4" class="white--text" type="submit"
                        :loading="$store.getters.isLoading"> {{ $validate.DataValid(id) ? '儲存' : '新增'}} </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import QuillEditor from '@/components/QuillEditor.vue';
import FormTextarea from '@/components/form/FormTextarea.vue';
import FormDatePicker from '@/components/form/FormDatePicker.vue';

export default {
    name: 'NewsDetails',
    components: {
        FormInput,
        ImageUploader,
        QuillEditor,
        FormTextarea,
        FormDatePicker
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        dispatchUpdateOnChange() {
            if (this.$validate.DataValid(this.id)) {
                return true;
            }
            return false;
        },
    },
    data: () => ({
        rowCount: 0,
        formData: {
            image: [],
            content: '',
            title: '',
            publish_date: ''
        },
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getNewsDetails() {
            try {
                const data = await this.$Fetcher.GetNewsById(this.id);
                this.$set(this.formData, 'content', data.content);
                this.$set(this.formData, 'title', data.title);
                this.$set(this.formData, 'publish_date', data.publish_date);
                if (this.$validate.DataValid(data.image)) {
                    this.formData.image.push(data.image);
                }
            } catch (err) {
                this.$common.error(err);
                this.setDialogMessage({
                    title: "找不到資料",
                    message: "找不到資料，請確保網址正確。",
                    isError: true,
                    returnLink: { name: 'NewsList' },
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const valid = await this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const payload = {
                image: this.$validate.DataValid(this.formData.image) ? this.formData.image[0] : '',
                title: this.formData.title,
                content: this.formData.content,
                publish_date: this.formData.publish_date,
            }

            if (this.$validate.DataValid(this.id)) {
                try {
                    await this.$Fetcher.UpdateNews(this.id, payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '更改成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });

                } catch (err) {
                    this.setDialogMessage({
                        title: "更改失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            } else {
                try {
                    await this.$Fetcher.AddNews(payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'NewsList'
                    });

                } catch (err) {
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }

            
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        if (this.$validate.DataValid(this.id)) {
            this.getNewsDetails();
        } else {
            this.$store.dispatch('toggleLoadingPage', false);
        }
       
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  